.btn-optin {
  color: rgb(218, 38, 38);
  background-color: #712687;
  border-color: #9409b5;
  text-transform: uppercase;
}
.btn-flat {
  background-color: purple;
  color: white;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}
.top {
  margin-bottom: 10px;
}
