.resources-menu {
    box-shadow: none;
}

.resources-menu-container {
    width: 240px;
    position: fixed;
    left: 0;
    top: 109px;
    bottom: 0;
    border-right: 1px solid #e8e8e8;
}

.resources-content {
    padding-left: 240px;
}

.resources-setting {
    width: 600px;
    margin-left: 20px;
}

.resources-play {
    padding: 20px;
}

.resources-play-report {
    color: red;
    cursor: pointer;
}

.resources-report {
    padding-left: 420px;
}

.resources-report-setting {
    position: fixed;
    left: 250px;
    top: 109px;
    width: 400px;
    bottom: 0;
    overflow-y: auto;
}

.resources-report-setting-content {
    margin-bottom: 16px;
}

.resources-report-setting-title {
    margin-bottom: 10px;
    font-weight: bold;
}

.resources-report-setting-row {
    display: flex;
    margin-bottom: 10px;
}

.resources-report-setting-row span {
    width: 90px;
    font-weight: bold;
}

.resources-report-content {
    padding-right: 20px;
}

.resources-report-item {
    padding: 20px;
    background-color: #e8e8e8;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.resources-report-top {
    display: flex;
    align-items: center;
}

.resources-report-item .icon {
    margin: 0 10px 0 0;
    width: 24px;
    height: 24px;
}

.resources-report-item-content {
    flex: 1;
    font-size: 18px;
    line-height: 28px;
    padding-left: 34px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.resources-report-time {
    font-size: 12px;
    color: #999;
}

.resources-profile-tip {
    font-size: 12px;
    color: #999;
    margin-bottom: 40px;
}

.resources-profile-container {
    display: flex;
}

.resources-profile-user {
    width: 300px;
}

.resources-profile-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: url('./images/boy.svg') no-repeat center center;
    background-size: contain;
    margin: 0 auto;
}

.resources-profile-name {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
}

.resources-profile-list {
    width: 500px;
}

.resources-profile-item {
    margin-bottom: 20px;
}

.resources-profile-item-title {
    font-size: 18px;
    margin-bottom: 8px;
}

.resources-profile-progress {
    height: 16px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.65625rem;
    background-color: #f7f7f9;
    position: relative;
}

.resources-profile-progress .bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: width 0.6s ease;
    overflow: hidden;
    color: #fff;
}

.resources-profile-progress .bar .text {
    position: absolute;
    color: #fff;
    right: 4px;
    top: 0;
    font-size: 12px;
    line-height: 16px;
}

.resources-profile-progress .bar-done {
    background-color: #1890ff;
    background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.resources-profile-progress .bar-correct {
    background-color: #4bbf73;
    background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.resources-profile-progress-bar-animated {
    animation: progress-bar-animated 1s linear infinite;
}

@keyframes progress-bar-animated {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.help {
    padding: 20px;
}

.resources-profile {
    padding: 20px;
}

.profile-question {
    display: flex;
    cursor: pointer;
}

.profile-question .question-content {
    flex: 1;
    margin-left: 10px;
}

.profile-pagination {
    margin-top: 50px;
    text-align: center;
}